<template>
    <div class="rota-type-banner">
        <!-- Currently single week -->
        <div class="banner" v-if="numberOfWeeks == 1">
            <div class="left">
                Do you have a multi-week rota pattern? Just let teamSOS know and we can enable multi-week support for
                you.
            </div>
        </div>
        <!-- / Currently single week -->

        <!-- Multi Week -->
        <div class="banner" v-if="numberOfWeeks > 1">
            <div class="left">
                <span v-if="isLoadingTimetableWeekNumber">
                    Loading...
                </span>
                <span v-if="timetableWeekNumber !== null && dateTimetableWeekNumberIsFor !== null">
                    Based on your rota dates, we think {{ dateTimetableWeekNumberIsFor.format('dddd Do MMMM Y') }} is in
                    {{ timetableWeekNumberHuman }}
                </span>
                <span
                    v-if="!isLoadingTimetableWeekNumber && !(timetableWeekNumber !== null && dateTimetableWeekNumberIsFor !== null)">
                    Couldn't determine which week the selected date is for.
                </span>
            </div>
            <div class="right">
                <a-button @click.prevent="updateRotaDates" icon="clock-circle">Update Rota Dates</a-button>
            </div>
        </div>
        <!-- / Multi Week -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters('admin', {
            selectedOrganisation: 'selectedOrganisation'
        }),

        ...mapGetters('adminAvailability', {
            dateTimetableWeekNumberIsFor: 'dateTimetableWeekNumberIsFor',
            timetableWeekNumber: 'timetableWeekNumber',
            isLoadingTimetableWeekNumber: 'isLoadingTimetableWeekNumber'
        }),

        numberOfWeeks() {
            if (this.selectedOrganisation['content']['$v']['timetableWeeksNullable']) {
                return this.selectedOrganisation['content']['$v']['timetableWeeksNullable'];
            }
            return 1;
        },

        timetableWeeksAlias() {
            if (this.selectedOrganisation['content']['$v']['timetableWeeksAlias']) {
                return this.selectedOrganisation['content']['$v']['timetableWeeksAlias'].split(',');
            }
            return ['Week 1'];
        },

        timetableWeekNumberHuman() {
            if (this.timetableWeekNumber !== null && this.timetableWeeksAlias[Number(this.timetableWeekNumber)]) {
                return this.timetableWeeksAlias[this.timetableWeekNumber];
            }
            return 'Week ' + (Number(this.timetableWeekNumber) + 1);
        }
    },
    methods: {
        updateRotaDates() {
            this.$router.push('/admin/rota-dates');
        }
    }
}
</script>

<style scoped lang="scss">
.banner {
    display: flex;
    // background-color: #e6f7ff;
    // border: 1px solid #91d5ff;
    // color: rgba(0, 0, 0, 0.85);
    // padding: 8px 15px;

    background: #5155ea;
    color: #fff;
    padding: 15px;

    border-radius: 4px;

    .left {
        flex-grow: 1;
        display: flex;
        align-items: center;
    }

    .right {
        flex-shrink: 1;
        display: flex;
        align-items: center;
    }
}
</style>