<template>
  <div class="admin-availability">
    <!-- Header -->
    <div class="page-header">
      <h1 class="page-title">
        <a-button type="default" style="display: inline; margin-right: 15px"
          @click.prevent="$router.push('/admin/responders')" icon="left"></a-button>

        <admin-org-indicator></admin-org-indicator>

        Admin - Responders - Availability
      </h1>
      <div class="actions"></div>
    </div>
    <!-- / Header -->

    <!-- Loading -->
    <div class="loader" v-if="isLoading">
      <a-spin></a-spin>
    </div>
    <!-- / Loading -->

    <!-- Loaded -->
    <div class="loaded" v-if="!isLoading">
      <!-- Search Panel -->
      <div v-if="!isLoading" class="search-panel">
        <a-row :gutter="20" align="middle" justify="center" type="flex">
          <a-col :span="5">
            <a-form-item style="margin-bottom: 20px" label="Date">
              <a-date-picker style="width: 80%" size="large" v-model="dateControl" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item style="margin-bottom: 20px" :label="`Time (${formatter(timeControl)})`">
              <a-slider v-model="timeControl" :tip-formatter="(value) => formatter(value)" :min="0" :max="1440"
                :step="15" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Response Team">
              <a-select v-model="skillIdControl" size="large" style="width: 100%" option-filter-prop="children"
                show-search :filter-option="filterOption">
                <!-- <a-select-option :value="null" disabled>Please select</a-select-option> -->
                <a-select-option v-for="skill in responseTeams" :value="skill.id" :key="`skill-${skill.id}`">
                  {{ skill.displayName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-button @click.prevent="searchAvailability" class="btn-rounded" block icon="search" size="large"
              type="primary">Search</a-button>
          </a-col>
        </a-row>
      </div>
      <!-- / Search Panel -->

      <!-- Rota Type Banner -->
      <rota-type-banner></rota-type-banner>
      <!-- / Rota Type Banner -->

      <!-- Is Loading Search Results -->
      <div v-if="isLoadingSearchResults" class="loader loading-search-results">
        <a-spin></a-spin>
      </div>
      <!-- / Is Loading Search Results -->

      <!-- Search Results -->
      <div v-if="hasSearchBeenPerformed && !isLoadingSearchResults" class="search-results">
        <!-- No search results -->
        <a-alert v-if="searchResults.length == 0" type="info" message="No responders to show" />
        <!-- / No search results -->

        <!-- Results -->
        <div v-if="searchResults.length">
          <availability-list-item v-for="(result, i) in searchResults" :key="`search-result-${i}`" :tenant-id="tenantId"
            :result="result"></availability-list-item>
        </div>
        <!-- / Results -->
      </div>
      <!-- / Search Results -->
    </div>
    <!-- / Loaded -->
  </div>
</template>

<script>
import skills from "../../../api/skills";
import { mapGetters, mapActions } from "vuex";
import AvailabilityListItem from "./Availability/AvailabilityListItem.vue";
import RotaTypeBanner from "../../../components/Availability/RotaTypeBanner.vue";
export default {
  data() {
    return {
      responseTeams: [],
      isLoading: false,
    };
  },
  components: { AvailabilityListItem, RotaTypeBanner },
  computed: {
    ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
      tenantId: "tenantId",
    }),

    ...mapGetters("adminAvailability", {
      time: "time",
      date: "date",
      skillId: "skillId",
      isLoadingSearchResults: "isLoading",
      hasSearchBeenPerformed: "hasSearchBeenPerformed",
      searchResults: "searchResults",
    }),

    timeControl: {
      get() {
        return this.time;
      },
      set(val) {
        this.setTime(val);
      },
    },

    dateControl: {
      get() {
        return this.date;
      },
      set(val) {
        this.setDate(val);
      },
    },

    skillIdControl: {
      get() {
        return this.skillId;
      },
      set(val) {
        this.setSkillId(val);
      },
    },
  },
  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
    this.loadResponseTeams();
  },
  methods: {
    ...mapActions("adminAvailability", {
      reset: "reset",
      searchAvailability: "searchAvailability",
      setTime: "setTime",
      setDate: "setDate",
      setSkillId: "setSkillId",
    }),

    formatter(value) {
      let hr = Math.floor(Number(value) / 60);
      let min = value % 60;

      return String(hr).padStart(2, "0") + ":" + String(min).padStart(2, "0");
    },

    loadResponseTeams() {
      let vm = this;
      vm.isLoading = true;
      vm.responseTeams = [];
      skills
        .getSkills(this.tenantId, this.selectedOrganisation.id, true)
        .then((r) => {
          vm.responseTeams = r.data;
          vm.isLoading = false;

          if (this.$route.query.reset !== "false") {
            this.reset(r.data);
            this.searchAvailability();
          }
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
          vm.$message.error("Error loading response teams");
        });
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>

<style lang="scss">
.admin-availability {
  padding-bottom: 20px;

  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .search-panel {
    background: #fff;
    background: #fff;
    padding: 10px 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
  }

  .loading-search-results {
    margin-top: 25px;
  }

  .rota-type-banner {
    margin-top: 20px;
  }

  .search-results {
    margin-top: 20px;
  }

  .availability-list-item {
    margin-bottom: 15px;
  }
}
</style>