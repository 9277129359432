<template>
  <div class="availability-list-item">
    <div class="left">
      <presence-thumbnail
        :ownerId="tenantId"
        size="large"
        :presenceId="presence.id"
      ></presence-thumbnail>
    </div>
    <div class="center">
      <div class="name">
        {{ result["result"].displayName }}
      </div>
      <div class="availability">
        <a-tag v-if="isAvailable" class="mode-tag" color="#23c552"
          >Available</a-tag
        >
        <a-tag v-if="!isAvailable" class="mode-tag" color="#f84f31"
          >Unvailable</a-tag
        >
      </div>
    </div>
    <div class="right">
      <a-button class="edit-rota-button" @click.prevent="editRota"
        >Edit Rota</a-button
      >
      <a-button @click.prevent="editExceptions">Edit Exceptions</a-button>
    </div>
  </div>
</template>

<script>
import PresenceThumbnail from "../../../../components/Presences/PresenceThumbnail.vue";
export default {
  props: ["tenantId", "result"],
  components: { PresenceThumbnail },
  computed: {
    presence() {
      return this.result["result"]["presence"];
    },

    isAvailable() {
      return Boolean(this.result["result"]["available"]);
    },
  },
  methods: {
    editRota() {
      this.$router.push(
        "/admin/responders/availability/" + this.presence.id + "/rota"
      );
    },

    editExceptions() {
      this.$router.push(
        "/admin/responders/availability/" + this.presence.id + "/exceptions"
      );
    },
  },
};
</script>

<style scoped lang="scss">
.availability-list-item {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  // cursor: pointer;
  border: 1px solid #fff;

  display: flex;

  .left {
    flex-shrink: 1;
    display: flex;
    align-items: center;

    min-width: 45px;
    text-align: center;
  }

  .center {
    padding-left: 15px;
    flex-grow: 1;
    display: flex;
    align-items: center;

    .name {
      font-weight: 500;
      padding-right: 20px;
    }
  }

  .right {
    flex-shrink: 1;
    padding-left: 10px;
    display: flex;
    align-items: center;

    .edit-rota-button {
      margin-right: 10px;
    }
  }
}
</style>